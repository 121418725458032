/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
if (window !== undefined && window.gtag !== undefined) {
	window.gtag('consent', 'default', { analytics_storage: 'denied' });
	window.gtag('consent', 'default', { ad_storage: 'denied' });
}
